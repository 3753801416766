<template>
  <main-layout>
    <v-container grid-list-xs>
      <v-layout row wrap class="mb-3">
        <v-flex xs12>
          <h2 class="headline mb-3">Griepvaccinatie</h2>
          <base-divider
            color="accent"
            dense
          />
          <h3 class="subtitle-1 pb-3"><strong>Voor wie?</strong></h3>
          <ul>
            <li>Alle personen vanaf 65 jaar</li>
            <li>Zwangere vrouwen: vraag advies aan je huisarts</li>
            <li>Personen die samenwonen met  kinderen jonger dan 6 maanden</li>
            <li>Alle personen die in een instelling opgenomen zijn</li>
            <li>Personen werkzaam in gezondheidssector</li>
            <li>Patiënten met een langdurige ziekte:</li>
            <li class="nostyle">
              <ul>
                <li>longziekten Vb: astma, chronische bronchitis</li>
                <li>hartziekten behalve hoge bloeddruk</li>
                <li>leverziekten</li>
                <li>nierziekten</li>
                <li>diabetes, schildklierziekten</li>
                <li>verminderde immuniteit Vb: kankerpatiënten, HIV</li>
              </ul>
            </li>
            <li class="nostyle"><p>EN alle personen die samenwonen met bovengenoemde patiënten</p></li>
          </ul>
          <h3 class="subtitle-1 pb-3"><strong>Wanneer?</strong></h3>
          <p>
            Het vaccin wordt best gezet in november en biedt bescherming gedurende 6 maanden.<br>
            Het is dus noodzakelijk om u elk jaar opnieuw te laten vaccineren.
          </p>
        </v-flex>
      </v-layout>
    </v-container>
  </main-layout>
</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    head () {
      return { title: this.$t('gmdvac') }
    },
    components: {
      MainLayout,
      BaseDivider
    }
  }
</script>

<style scoped>

</style>
